import moment from "moment";
import { useContext, useEffect } from "react";
import { Flex, Text, TextField } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import { momentToDate } from "../../utils/utils";

const DayFields = () => {
  const { start, setStart, areFieldsDisabled } = useContext(ScheduleContext);

  useEffect(() => {
    if (!start) {
      setStart(momentToDate(moment()));
    }
  }, [start, setStart]);

  const onStartDateChange = (value) => {
    setStart(value);
  };

  return (
    <Flex gap={52}>
      <Text type="text1">Start Date</Text>

      <TextField
        type="date"
        wrapperClassName="start-date-wrapper"
        value={start}
        onChange={onStartDateChange}
        disabled={areFieldsDisabled}
      />
    </Flex>
  );
};

export default DayFields;
