import moment from "moment";
import { fullMonths, fullWeekdays, weekdays } from "./constants";

const multipleWordsToStr = (words) => {
  if (words.length < 1) {
    return "";
  }

  if (words.length === 1) {
    return words[0];
  }

  let str = words[0];
  for (let i = 1; i < words.length - 1; i++) {
    str += `, ${words[i]}`;
  }
  str += ` and ${words[words.length - 1]}`;
  return str;
};

export const capitalize = (str) => {
  if (!str) {
    return "";
  }
  return str
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");
};

const ordinalNumber = (num) => {
  const suffixes = ["th", "st", "nd", "rd"];
  return num + (suffixes[(num - 20) % 10] || suffixes[num] || suffixes[0]);
};

const dayOfMonth = (selectedDay) => {
  const parts = selectedDay.split("-");

  if (parts.length === 1) {
    return parts[0] === "last" ? "last day" : ordinalNumber(parseInt(parts[0]));
  }
  if (parts.length === 2) {
    const weekNumber = parts[0] === "last" ? "last" : ordinalNumber(parseInt(parts[0]));
    return `${weekNumber} ${fullWeekdays[parseInt(parts[1]) - 1]}`;
  }
};

const dayOfYear = (selectedDay) => {
  const parts = selectedDay.split("-");

  let monthDay;
  let month;

  if (parts.length === 2) {
    monthDay = dayOfMonth(parts[0]);
    month = parseInt(parts[1]);
  }
  if (parts.length === 3) {
    monthDay = dayOfMonth(parts[0] + "-" + parts[1]);
    month = parseInt(parts[2]);
  }

  return `${monthDay} of ${fullMonths[month - 1]}`;
};

export const weekOfMonth = (num) => {
  if (num === "last") {
    return "Last";
  }

  return ordinalNumber(parseInt(num));
};

export const recurrenceText = ({ period, granularity, selectedDays }) => {
  if (!period || !granularity || (granularity !== "day" && selectedDays.length === 0)) {
    return "";
  }

  let recurrence = "Every ";

  if (period > 1) {
    recurrence += `${period} ${granularity}s`;
  } else {
    recurrence += `${granularity}`;
  }

  if (granularity !== "day") {
    recurrence += " on ";
  }

  switch (granularity) {
    case "week": {
      let weekdayIndices = selectedDays.map((day) => Number(day) - 1);
      weekdayIndices.sort();

      if (weekdayIndices.length > 3) {
        recurrence += multipleWordsToStr(weekdayIndices.map((index) => weekdays[index]));
      } else {
        recurrence += multipleWordsToStr(weekdayIndices.map((index) => fullWeekdays[index]));
      }

      break;
    }
    case "month": {
      recurrence += `the ${dayOfMonth(selectedDays[0])}`;
      break;
    }
    case "year": {
      recurrence += `the ${dayOfYear(selectedDays[0])}`;
      break;
    }
    default:
      break;
  }

  return recurrence;
};

const dayBasedMonthDate = (year, month, day, period, granularity) => {
  const today = moment();
  const monthStart = moment().set({ year, month, date: 1 });

  while (true) {
    let date;
    if (day === "last") {
      date = monthStart.clone().endOf("month");
    } else {
      date = monthStart.clone().add(Number(day) - 1, "day");
    }

    if (date >= today) {
      return date;
    }
    monthStart.add(period, granularity);
  }
};

const weekdayBasedMonthDate = (year, month, weekNumber, weekday, period, granularity) => {
  const today = moment();
  let monthStart = moment().set({ year, month, date: 1 });

  while (true) {
    let weekdayDate;
    if (weekNumber === "last") {
      weekdayDate = monthStart.clone().endOf("month").day(weekday);
      if (weekdayDate.month() !== monthStart.month()) {
        weekdayDate.subtract(1, "week");
      }
    } else {
      weekdayDate = monthStart.clone().startOf("month").day(weekday);
      if (weekdayDate.month() !== monthStart.month()) {
        weekdayDate.add(1, "week");
      }
      weekdayDate.add(Number(weekNumber) - 1, "week");
    }

    if (weekdayDate > today) {
      return weekdayDate;
    }
    monthStart = monthStart.add(period, granularity);
  }
};

export const getNextScheduledDate = ({ period, granularity, selectedDays, start }) => {
  if (!period || !granularity || !start || (granularity !== "day" && selectedDays.length === 0)) {
    return "";
  }

  const today = moment();

  switch (granularity) {
    case "day": {
      const date = moment(start);
      while (date <= today) {
        date.add(period, granularity);
      }
      return date;
    }
    case "week": {
      const weekdays = selectedDays.map((day) => Number(day) - 1);
      weekdays.sort();

      let date;
      let weekStartDate = moment(start);

      while (true) {
        date = weekStartDate.clone();
        for (let weekday of weekdays) {
          date = weekStartDate.clone().add(weekday, "day");
          if (date > today) {
            return date;
          }
        }
        weekStartDate.add(period, granularity);
      }
    }
    case "month": {
      let startMonth = Number(start.split("-")[1]) - 1;
      let startYear = Number(start.split("-")[0]);

      const parts = selectedDays[0].split("-");
      if (parts.length === 1) {
        const day = parts[0];
        return dayBasedMonthDate(startYear, startMonth, day, period, granularity);
      }

      if (parts.length === 2) {
        const weekNumber = parts[0];
        const weekday = Number(parts[1]) - 1;
        return weekdayBasedMonthDate(
          startYear,
          startMonth,
          weekNumber,
          weekday,
          period,
          granularity
        );
      }
      return "";
    }
    case "year": {
      let startYear = Number(start);

      const parts = selectedDays[0].split("-");
      if (parts.length === 2) {
        const day = parts[0];
        const month = Number(parts[1]) - 1;
        return dayBasedMonthDate(startYear, month, day, period, granularity);
      }

      if (parts.length === 3) {
        const weekNumber = parts[0];
        const weekday = Number(parts[1]) - 1;
        const month = Number(parts[2]) - 1;
        return weekdayBasedMonthDate(startYear, month, weekNumber, weekday, period, granularity);
      }
      return "";
    }
    default: {
      return "";
    }
  }
};

export const userFriendlyNextScheduledDate = (recurrence) => {
  const nextScheduledDate = getNextScheduledDate(recurrence);
  if (!nextScheduledDate) return "";

  return nextScheduledDate.toDate().toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
};

// -----------------------------------------------------------------------------------------------
// Following code is exactly same as BE code for similar purpose. If any changes are made here,
// ensure that same changes are made in BE code as well.

export const momentToDate = (moment) => {
  return moment.format("YYYY-MM-DD");
};

const getWeekdayAndWeekNumber = (date) => {
  const dateStr = momentToDate(date);
  const weekday = date.day();

  let weekNumber;
  let weekdayDate = date.clone().startOf("month").day(weekday);
  if (weekdayDate.month() !== date.month()) {
    weekdayDate.add(1, "week");
  }

  for (let i = 0; i < 5; i++) {
    if (momentToDate(weekdayDate.clone().add(i, "week")) === dateStr) {
      weekNumber = i + 1;
      break;
    }
  }

  let isLastWeek = false;
  weekdayDate = date.clone().endOf("month").day(weekday);
  if (weekdayDate.month() !== date.month()) {
    weekdayDate.subtract(1, "week");
  }
  if (momentToDate(weekdayDate) === dateStr) {
    isLastWeek = true;
  }

  return { weekday, weekNumber, isLastWeek };
};

const isMonthDaySame = (day, date) => {
  const dayDate = day === "last" ? date.daysInMonth() : Number(day);
  return dayDate === date.date();
};

const isMonthWeekdaySame = (weekday, weekNumber, date) => {
  const {
    weekday: dateWeekday,
    weekNumber: dateWeekNumber,
    isLastWeek,
  } = getWeekdayAndWeekNumber(date);

  if (weekday !== dateWeekday) {
    return false;
  }
  if (weekNumber === "last") {
    return isLastWeek;
  }

  return Number(weekNumber) === dateWeekNumber;
};

export const shouldCreateItemOnDate = (recurrence, date) => {
  const { granularity, period, selectedDays, start } = recurrence;
  if (!start) {
    return;
  }

  switch (granularity) {
    case "day": {
      const startDate = moment(start);
      const diff = date.diff(startDate, granularity);
      return diff >= 0 && diff % period === 0;
    }
    case "week": {
      if (!selectedDays.includes((date.day() + 1).toString())) {
        return false;
      }

      const sunday = moment(date).day(0);
      const startWeek = moment(start);
      const diff = sunday.diff(startWeek, granularity);
      return diff >= 0 && diff % period === 0;
    }
    case "month": {
      const parts = selectedDays[0].split("-");
      if (parts.length === 1) {
        if (!isMonthDaySame(parts[0], date)) {
          return false;
        }
      }

      if (parts.length === 2) {
        const weekNumber = parts[0];
        const weekday = Number(parts[1]) - 1;
        if (!isMonthWeekdaySame(weekday, weekNumber, date)) {
          return false;
        }
      }

      const monthValue = date.year() * 12 + date.month();
      const startMonthDate = moment(start, "YYYY-MM");
      const startMonthValue = startMonthDate.year() * 12 + startMonthDate.month();

      const diff = monthValue - startMonthValue;
      return diff >= 0 && diff % period === 0;
    }
    case "year": {
      const diff = date.year() - parseInt(start);
      if (diff < 0 || diff % period !== 0) {
        return false;
      }

      const parts = selectedDays[0].split("-");
      const month = parseInt(parts.pop()) - 1;
      if (date.month() !== month) {
        return false;
      }

      if (parts.length === 1) {
        if (!isMonthDaySame(parts[0], date)) {
          return false;
        }
      }

      if (parts.length === 2) {
        const weekNumber = parts[0];
        const weekday = Number(parts[1]) - 1;
        if (!isMonthWeekdaySame(weekday, weekNumber, date)) {
          return false;
        }
      }
      return true;
    }
    default:
      return false;
  }
};

export const populateCustomName = (customName, date) => {
  const variables = customName?.match(/\{([^{}]+)\}/g)?.map((match) => match.slice(1, -1)) || [];
  if (!variables.length) {
    return customName;
  }

  let finalText = customName;
  for (let variable of variables) {
    let replacementText = "";
    switch (variable) {
      case "day": {
        replacementText = date.format("dddd");
        break;
      }
      case "date": {
        replacementText = date.format("DD");
        break;
      }
      case "month": {
        replacementText = date.format("MMMM");
        break;
      }
      case "year": {
        replacementText = date.format("YYYY");
        break;
      }
      case "quarter": {
        replacementText = `Q${date.quarter()}`;
        break;
      }
      case "half": {
        replacementText = `H${date.month() < 6 ? 1 : 2}`;
        break;
      }
      default: {
      }
    }
    finalText = finalText.replaceAll(`{${variable}}`, replacementText);
  }
  return finalText;
};

// -----------------------------------------------------------------------------------------------

export const getCalendarGridDates = (month, year) => {
  const firstDateOfMonth = moment().month(month).year(year).startOf("month");
  const firstDateOfGrid = firstDateOfMonth.startOf("week").day(0);
  const lastDateOfMonth = moment().month(month).year(year).endOf("month");
  const lastDateOfGrid = lastDateOfMonth.endOf("week").day(6);

  const dates = [];
  let date = firstDateOfGrid;

  while (date <= lastDateOfGrid) {
    dates.push(moment(date));
    date.add(1, "day");
  }
  return dates;
};

export const updateMissingFields = (
  fieldName,
  fieldValue,
  options,
  missingFields,
  setMissingFields
) => {
  if (fieldValue) {
    if (!options.includes(fieldValue)) {
      if (!missingFields[fieldName]) {
        setMissingFields({ ...missingFields, [fieldName]: true });
      }
    } else {
      if (missingFields[fieldName]) {
        setMissingFields({ ...missingFields, [fieldName]: false });
      }
    }
  } else {
    if (missingFields[fieldName]) {
      setMissingFields({ ...missingFields, [fieldName]: false });
    }
  }
};
