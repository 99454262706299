import { useContext } from "react";
import { Flex, Loader } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import Button from "./Button";

const ButtonGroup = () => {
  const {
    scheduleId,
    isActive,
    isLoading,
    inEditMode,
    setInEditMode,
    isUserViewer,
    createSchedule,
    updateSchedule,
    unschedule,
    reschedule,
    deleteSchedule,
  } = useContext(ScheduleContext);

  const disabled = isUserViewer;

  return (
    <Flex direction="Column" gap={10} className="button-group">
      {isLoading ? (
        <Loader size={40} />
      ) : !scheduleId ? (
        <Button text="Schedule" color="positive" onClick={createSchedule} disabled={disabled} />
      ) : !isActive ? (
        <Flex gap={10}>
          <Button text="Reschedule" color="positive" onClick={reschedule} disabled={disabled} />
          <Button text="Delete" color="negative" onClick={deleteSchedule} disabled={disabled} />
        </Flex>
      ) : inEditMode ? (
        <Button text="Save Changes" color="positive" onClick={updateSchedule} disabled={disabled} />
      ) : (
        <>
          <Button
            text="Edit Schedule"
            color="primary"
            onClick={() => setInEditMode(true)}
            disabled={disabled}
          ></Button>

          <Flex gap={10}>
            <Button
              text="Unschedule"
              extraClassName="purple-bg"
              onClick={unschedule}
              disabled={disabled}
            />
            <Button text="Delete" color="negative" onClick={deleteSchedule} disabled={disabled} />
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default ButtonGroup;
