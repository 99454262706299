import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Flex, Text, Dropdown, TextField, Checkbox } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import { DUE_DATE_COLUMN_FIELD } from "../../utils/constants";
import { updateMissingFields } from "../../utils/utils";

const DueDateSelector = () => {
  const {
    columns,
    dueDateColumnId,
    setDueDateColumnId,
    duePostDays,
    setDuePostDays,
    missingFields,
    setMissingFields,
    areFieldsDisabled,
    setColumnTypeToAdd,
    setColumnAddCallback,
  } = useContext(ScheduleContext);

  const [isFieldActive, setIsFieldActive] = useState(false);

  const columnOptions = useMemo(
    () => [
      ...columns
        .filter((column) => column.type === "date")
        .map(({ id, title }) => ({ value: id, label: title })),
      { value: "add_column", label: "+ Add column" },
    ],
    [columns]
  );

  const duePostDaysRef = useRef(null);

  useEffect(() => {
    if (duePostDaysRef.current) {
      duePostDaysRef.current.setAttribute("min", "0");
    }
  }, []);

  useEffect(() => {
    if (dueDateColumnId) {
      setIsFieldActive(true);
    }

    updateMissingFields(
      DUE_DATE_COLUMN_FIELD,
      dueDateColumnId,
      columnOptions.map(({ value }) => value),
      missingFields,
      setMissingFields
    );
  }, [dueDateColumnId, columnOptions, missingFields, setMissingFields]);

  const selectedColumnOption = dueDateColumnId
    ? columnOptions.filter((option) => option.value === dueDateColumnId)[0]
    : null;

  const onFieldToggle = (e) => {
    if (!e.target.checked) {
      setDueDateColumnId("");
      setDuePostDays(0);
    }
    setIsFieldActive(e.target.checked);
  };

  const onColumnChange = (option) => {
    if (option?.value === "add_column") {
      setColumnTypeToAdd("date");
      setColumnAddCallback(() => (option) => onColumnChange(option));
    } else {
      setDueDateColumnId(option ? option.value : "");
      if (!option) {
        setDuePostDays(0);
        setIsFieldActive(false);
      }
    }
  };

  const onDaysChange = (value) => {
    value = Number(value);
    setDuePostDays(Math.max(0, value));
  };

  return (
    <Flex gap={10}>
      <Checkbox
        labelClassName="checkbox-label"
        checked={isFieldActive}
        onChange={onFieldToggle}
        disabled={areFieldsDisabled}
      />

      <Text type="text1" color={isFieldActive ? "primary" : "secondary"}>
        Set Due Date
      </Text>

      <div className="due-date">
        <Dropdown
          options={columnOptions}
          size="small"
          value={selectedColumnOption}
          onChange={onColumnChange}
          disabled={!isFieldActive || areFieldsDisabled}
        />
      </div>

      <TextField
        type="number"
        wrapperClassName="period-wrapper"
        className="period"
        ref={duePostDaysRef}
        value={duePostDays}
        onChange={onDaysChange}
        disabled={!isFieldActive || areFieldsDisabled || !dueDateColumnId}
      />

      <Text type="text1" color={isFieldActive ? "primary" : "secondary"}>
        days from creation
      </Text>
    </Flex>
  );
};

export default DueDateSelector;
