import { useContext } from "react";
import ScheduleContext from "../../context/ScheduleContext";
import { recurrenceText, userFriendlyNextScheduledDate } from "../../utils/utils";

const NextScheduledDate = () => {
  const { period, granularity, selectedDays, start } = useContext(ScheduleContext);

  const nextScheduledDate = userFriendlyNextScheduledDate({
    period,
    granularity,
    selectedDays,
    start,
  });

  const recurrence = recurrenceText({ period, granularity, selectedDays });

  return (
    nextScheduledDate && (
      <div className="next-scheduled-date">
        <p>{`Repeats ${recurrence.charAt(0).toLowerCase()}${recurrence.slice(1)}`}</p>
        <div>
          <p>As per this recurrence, next scheduled run will happen on </p>
          <p>{nextScheduledDate}</p>
        </div>
      </div>
    )
  );
};

export default NextScheduledDate;
