import { useContext, useEffect, useRef } from "react";
import { Flex, Dropdown, Text, TextField } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";

const PeriodSelector = () => {
  const granularities = ["day", "week", "month", "year"];

  const {
    period,
    setPeriod,
    granularity,
    setGranularity,
    setSelectedDays,
    setStart,
    areFieldsDisabled,
  } = useContext(ScheduleContext);

  const periodRef = useRef(null);

  useEffect(() => {
    if (periodRef.current) {
      periodRef.current.setAttribute("min", "1");
    }
  }, []);

  const options = granularities.map((granularity) => ({
    value: granularity,
    label: granularity.charAt(0).toUpperCase() + granularity.slice(1) + (period > 1 ? "s" : ""),
  }));

  const selectedOption = options.filter((option) => option.value === granularity)[0];

  const onPeriodChange = (value) => {
    value = Number(value);
    setPeriod(Math.max(1, value));
  };

  const onGranularityChange = (option) => {
    setGranularity(option.value);
    setSelectedDays([]);
    setStart("");
  };

  return (
    <Flex gap={30}>
      <Text type="text1">Repeat Every</Text>

      <Flex gap={10}>
        <TextField
          type="number"
          className="period"
          wrapperClassName="period-wrapper"
          ref={periodRef}
          value={period}
          onChange={onPeriodChange}
          disabled={areFieldsDisabled}
        />

        <div className="granularity">
          <Dropdown
            size="small"
            clearable={false}
            options={options}
            value={selectedOption}
            onChange={onGranularityChange}
            disabled={areFieldsDisabled}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default PeriodSelector;
