import { useContext } from "react";
import ScheduleContext from "../../context/ScheduleContext";
import AddEntityModal from "./AddEntityModal";

const AddColumnModal = () => {
  const { columnTypeToAdd, setColumnTypeToAdd, addNewColumn } = useContext(ScheduleContext);

  const onAdd = async (columnName) => {
    await addNewColumn(columnName);
  };

  const onCancel = () => {
    setColumnTypeToAdd("");
  };

  return (
    <AddEntityModal
      entityType="column"
      columnType={columnTypeToAdd}
      showModal={columnTypeToAdd !== ""}
      onAdd={onAdd}
      onCancel={onCancel}
    />
  );
};

export default AddColumnModal;
