import moment from "moment";
import { useContext, useState } from "react";
import ScheduleContext from "../../context/ScheduleContext";
import { getCalendarGridDates, shouldCreateItemOnDate } from "../../utils/utils";

const RecurrenceVisualizer = () => {
  const { period, granularity, selectedDays, start } = useContext(ScheduleContext);

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());

  const onPrev = () => {
    const date = moment().month(month).year(year).subtract(1, "month");
    setMonth(date.month());
    setYear(date.year());
  };

  const onNext = () => {
    const date = moment().month(month).year(year).add(1, "month");
    setMonth(date.month());
    setYear(date.year());
  };

  return (
    <div className="recurrence-calendar">
      <div className="month-nav">
        <i className="fas fa-angle-left" onClick={onPrev} />
        <h3>
          {moment().month(month).format("MMMM")} {moment().year(year).format("YYYY")}
        </h3>
        <i className="fas fa-angle-right" onClick={onNext} />
      </div>

      <ul className="weekdays">
        <li>S</li>
        <li>M</li>
        <li>T</li>
        <li>W</li>
        <li>T</li>
        <li>F</li>
        <li>S</li>
      </ul>

      <div className="date-grid">
        {getCalendarGridDates(month, year).map((date, idx) => (
          <div
            key={idx}
            className={`date-cell ${date.month() === month && "current-month-date"} ${
              shouldCreateItemOnDate({ period, granularity, selectedDays, start }, date) &&
              "selected"
            }`}
          >
            <p>{date.date()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecurrenceVisualizer;
