import { Button as UIButton } from "monday-ui-react-core";

const Button = ({ text, onClick, disabled, color, extraClassName }) => {
  return (
    <UIButton
      className={`button ${extraClassName ?? ""}`}
      color={`${color ?? ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </UIButton>
  );
};

export default Button;
