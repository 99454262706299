import { useContext, useEffect, useState } from "react";
import { Text } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";

const MissingFieldsWarning = () => {
  const { missingFields } = useContext(ScheduleContext);

  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowWarning(true);
    }, 800);
  }, []);

  return (
    Object.values(missingFields).some((value) => value) &&
    showWarning && (
      <div className="missing-fields-warning">
        <Text>Following field values set earlier are no longer present in this board:</Text>

        <ul>
          {Object.entries(missingFields).map(
            ([key, value]) =>
              value && (
                <li key={key}>
                  <Text>{key}</Text>
                </li>
              )
          )}
        </ul>

        <Text>Please fix these first and update the schedule. </Text>
      </div>
    )
  );
};

export default MissingFieldsWarning;
