import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ScheduleProvider } from "./context/ScheduleContext";
import { SchedulesListProvider } from "./context/SchedulesListContext";
import BoardView from "./views/BoardView";
import ItemView from "./views/ItemView";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ScheduleProvider>
                <ItemView />
              </ScheduleProvider>
            }
          />
          <Route
            path="/list"
            element={
              <SchedulesListProvider>
                <BoardView />
              </SchedulesListProvider>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
