import moment from "moment-timezone";
import { useContext } from "react";
import { Flex, Text, Dropdown } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";

const utcOffset = (timezone) => {
  const utcOffset = moment.tz(timezone).utcOffset();
  const hours = Math.floor(Math.abs(utcOffset) / 60).toString();
  const minutes = (Math.abs(utcOffset) % 60).toString();
  const sign = utcOffset < 0 ? "-" : "+";
  return `${sign}${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
};

const TimezoneSelector = () => {
  const { timezones, timezone, setTimezone, areFieldsDisabled } = useContext(ScheduleContext);

  const options = timezones.map(({ name, timezone }) => ({
    value: name,
    label: `(GMT${utcOffset(timezone)}) ${name}`,
  }));

  const selectedOption = options.filter((option) => option.value === timezone)[0];

  const onTimezoneChange = (option) => {
    setTimezone(option.value);
  };

  return (
    <Flex gap={55}>
      <Text type="text1">Timezone</Text>

      <div className="timezone">
        <Dropdown
          size="small"
          clearable={false}
          options={options}
          value={selectedOption}
          onChange={onTimezoneChange}
          disabled={areFieldsDisabled}
        />
      </div>
    </Flex>
  );
};

export default TimezoneSelector;
