import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Flex, Text, Dropdown, RadioButton } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import { fullWeekdays, monthDays, weekNumbers } from "../../utils/constants";
import { capitalize, weekOfMonth } from "../../utils/utils";

const dayOptions = monthDays.map((monthDay) => ({
  value: monthDay,
  label: capitalize(monthDay),
}));

const weekNumberOptions = weekNumbers.map((weekNumber) => ({
  value: weekNumber,
  label: weekOfMonth(weekNumber),
}));

const weekdayOptions = fullWeekdays.map((weekday, idx) => ({
  value: (idx + 1).toString(),
  label: weekday,
}));

const monthOptions = (() => {
  const date = moment().startOf("month");

  const monthStartDates = [];
  for (let i = 0; i < 12; i++) {
    monthStartDates.push(date.clone());
    date.add(1, "month");
  }

  return monthStartDates.map((date) => ({
    value: date.format("YYYY-MM"),
    label: `${date.format("MMM")} ${date.format("YYYY")}`,
  }));
})();

const MonthFields = () => {
  const { selectedDays, setSelectedDays, start, setStart, areFieldsDisabled } =
    useContext(ScheduleContext);

  const [dayOptionsEnabled, setDayOptionsEnabled] = useState(true);
  const [startMonthOptions, setStartMonthOptions] = useState(monthOptions);

  useEffect(() => {
    if (selectedDays.length === 0) {
      setSelectedDays([dayOptions[0].value]);
    } else {
      setDayOptionsEnabled(selectedDays[0].split("-").length === 1);
    }
  }, [selectedDays, setSelectedDays]);

  useEffect(() => {
    if (start === "") {
      setStart(startMonthOptions[0].value);
    } else {
      if (startMonthOptions.every(({ value }) => value !== start)) {
        const date = moment(start, "YYYY-MM");
        setStartMonthOptions([
          { value: start, label: `${date.format("MMM")} ${date.format("YYYY")}` },
          ...startMonthOptions,
        ]);
      }
    }
  }, [start, setStart, startMonthOptions]);

  let selectedDayOption = dayOptions[0];
  let selectedWeekNumberOption = weekNumberOptions[0];
  let selectedWeekdayOption = weekdayOptions[0];

  if (selectedDays.length) {
    const parts = selectedDays[0].split("-");
    if (parts.length === 1) {
      selectedDayOption = dayOptions.find(({ value }) => value === parts[0]);
    } else if (parts.length === 2) {
      selectedWeekNumberOption = weekNumberOptions.find(({ value }) => value === parts[0]);
      selectedWeekdayOption = weekdayOptions.find(({ value }) => value === parts[1]);
    }
  }

  const selectedStartMonthOption = start
    ? startMonthOptions.find((option) => option.value === start)
    : startMonthOptions[0];

  const onDayChange = (option) => {
    setSelectedDays([option.value]);
  };

  const onWeekNumberChange = (option) => {
    setSelectedDays([`${option.value}-${selectedWeekdayOption.value}`]);
  };

  const onWeekdayChange = (option) => {
    setSelectedDays([`${selectedWeekNumberOption.value}-${option.value}`]);
  };

  const onStartMonthChange = (option) => {
    setStart(option.value);
  };

  const onToggle = () => {
    if (dayOptionsEnabled) {
      setSelectedDays([`${selectedWeekNumberOption.value}-${selectedWeekdayOption.value}`]);
      setDayOptionsEnabled(false);
    } else {
      setSelectedDays([selectedDayOption.value]);
      setDayOptionsEnabled(true);
    }
  };

  return (
    <Flex direction="Column" gap={30} align="Start">
      <Flex gap={28}>
        <Text type="text1">Day of Month</Text>

        <Flex direction="Column" align="Start" gap={10} style={{ marginLeft: "-1rem" }}>
          <Flex>
            <RadioButton
              checked={dayOptionsEnabled}
              onSelect={onToggle}
              disabled={areFieldsDisabled}
            />

            <div className="month-day">
              <Dropdown
                size="small"
                clearable={false}
                options={dayOptions}
                value={selectedDayOption}
                onChange={onDayChange}
                disabled={!dayOptionsEnabled || areFieldsDisabled}
              />
            </div>
          </Flex>

          <Flex>
            <RadioButton
              checked={!dayOptionsEnabled}
              onSelect={onToggle}
              disabled={areFieldsDisabled}
            />

            <Flex gap={10}>
              <div className="month-week-number">
                <Dropdown
                  size="small"
                  clearable={false}
                  options={weekNumberOptions}
                  value={selectedWeekNumberOption}
                  onChange={onWeekNumberChange}
                  disabled={dayOptionsEnabled || areFieldsDisabled}
                />
              </div>

              <div className="month-weekday">
                <Dropdown
                  size="small"
                  clearable={false}
                  options={weekdayOptions}
                  value={selectedWeekdayOption}
                  onChange={onWeekdayChange}
                  disabled={dayOptionsEnabled || areFieldsDisabled}
                />
              </div>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap={39}>
        <Text type="text1">Start Month</Text>

        <div className="start-month">
          <Dropdown
            size="small"
            clearable={false}
            options={startMonthOptions}
            value={selectedStartMonthOption}
            onChange={onStartMonthChange}
            disabled={areFieldsDisabled}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default MonthFields;
