import { useContext, useEffect, useState } from "react";
import { Flex, AttentionBox } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";

const ScheduleStatus = () => {
  const { scheduleId, isActive, inEditMode } = useContext(ScheduleContext);

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    let message = "";
    let type = "";

    if (inEditMode) {
      message = 'You are in edit mode. Press "Save Changes" to update the schedule';
      type = "warning";
    } else if (isActive) {
      message = "Following schedule is currently active";
      type = "success";
    } else {
      message = 'Schedule is currently inactive. Click "Reschedule" to activate again';
      type = "danger";
    }

    setMessage(message);
    setType(type);
  }, [isActive, inEditMode]);

  return (
    scheduleId && (
      <Flex>
        <AttentionBox className="schedule-status" text={message} type={type} />
      </Flex>
    )
  );
};

export default ScheduleStatus;
