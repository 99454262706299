import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Flex, Checkbox, Text, Dropdown } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import { weekdays } from "../../utils/constants";
import { momentToDate } from "../../utils/utils";

const weekStartDateOptions = (() => {
  const startDate = moment().startOf("week").day(0);
  const endDate = startDate.clone().add(1, "year");

  const weekStartDates = [];
  for (let date = startDate; date <= endDate; date.add(1, "week")) {
    weekStartDates.push(date.clone());
  }

  return weekStartDates.map((date) => ({
    value: momentToDate(date),
    label: date.toDate().toLocaleDateString(),
  }));
})();

const WeekFields = () => {
  const { selectedDays, setSelectedDays, start, setStart, areFieldsDisabled } =
    useContext(ScheduleContext);

  const [startWeekOptions, setStartWeekOptions] = useState(weekStartDateOptions);

  useEffect(() => {
    if (!selectedDays.length) {
      setSelectedDays(["2"]);
    }
  }, [selectedDays, setSelectedDays]);

  useEffect(() => {
    if (!start) {
      setStart(startWeekOptions[0].value);
    } else {
      if (startWeekOptions.every(({ value }) => value !== start)) {
        setStartWeekOptions([
          { value: start, label: new Date(start).toLocaleDateString() },
          ...startWeekOptions,
        ]);
      }
    }
  }, [start, setStart, startWeekOptions]);

  const selectedStartWeekOption = start
    ? startWeekOptions.find(({ value }) => value === start)
    : startWeekOptions[0];

  const onToggle = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((selectedDays) => selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays((selectedDays) => [...selectedDays, day]);
    }
  };

  const onStartWeekChange = (option) => {
    setStart(option.value);
  };

  return (
    <Flex direction="Column" gap={20} align="Start">
      <Flex direction="Row" gap={25}>
        <Text type="text1">Days of Week</Text>

        <Flex gap={10}>
          {weekdays.map((weekday, dayIndex) => (
            <Flex
              direction="Column"
              align="Center"
              justify="Center"
              className="weekday"
              key={dayIndex}
              gap={2}
            >
              <Checkbox
                labelClassName="checkbox-label"
                checked={selectedDays.includes((dayIndex + 1).toString())}
                onChange={() => onToggle((dayIndex + 1).toString())}
                disabled={areFieldsDisabled}
              />
              <Text>{weekday}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Flex gap={46}>
        <Text type="text1">Start Week</Text>

        <div className="start-week">
          <Dropdown
            size="small"
            clearable={false}
            options={startWeekOptions}
            value={selectedStartWeekOption}
            onChange={onStartWeekChange}
            disabled={areFieldsDisabled}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default WeekFields;
