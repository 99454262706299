import { useContext, useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import { Flex, Text, Dropdown, Checkbox } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import { TARGET_GROUP_FIELD } from "../../utils/constants";
import { updateMissingFields } from "../../utils/utils";
import AddEntityModal from "./AddEntityModal";

const monday = mondaySdk();

const TargetGroupSelector = () => {
  const {
    boardId,
    targetGroupId,
    setTargetGroupId,
    missingFields,
    setMissingFields,
    areFieldsDisabled,
  } = useContext(ScheduleContext);

  const [isFieldActive, setIsFieldActive] = useState(false);
  const [groupsOptions, setGroupOptions] = useState([]);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);

  useEffect(() => {
    if (!boardId) {
      return;
    }

    const getGroups = async () => {
      const query = `query ($boardId: ID!) {
        boards (ids: [$boardId]) {
          groups {
            id
            title
          }
        }
      }`;
      const variables = { boardId };

      try {
        const response = await monday.api(query, { variables });
        const board = response.data.boards[0];
        setGroupOptions([
          ...board.groups.map(({ id, title }) => ({ value: id, label: title })),
          { value: "add_group", label: "+ Add group" },
        ]);
      } catch (error) {
        console.error("Get groups error:", error.message);
      }
    };

    getGroups();
  }, [boardId]);

  useEffect(() => {
    if (targetGroupId) {
      setIsFieldActive(true);
    }

    updateMissingFields(
      TARGET_GROUP_FIELD,
      targetGroupId,
      groupsOptions.map(({ value }) => value),
      missingFields,
      setMissingFields
    );
  }, [targetGroupId, groupsOptions, missingFields, setMissingFields]);

  const selectedOption = targetGroupId
    ? groupsOptions.filter((option) => option.value === targetGroupId)[0]
    : null;

  const onFieldToggle = (e) => {
    if (!e.target.checked) {
      setTargetGroupId("");
    }
    setIsFieldActive(e.target.checked);
  };

  const onGroupChange = (option) => {
    if (option?.value === "add_group") {
      setShowAddGroupModal(true);
    } else {
      setTargetGroupId(option ? option.value : "");
      if (!option) {
        setIsFieldActive(false);
      }
    }
  };

  const onAddNewGroup = async (groupName) => {
    const query = `mutation ($boardId: ID!, $groupName: String!) {
      create_group (board_id: $boardId, group_name: $groupName, position_relative_method: before_at) {
        id
        title
      }
    }`;
    const variables = { boardId, groupName };

    try {
      const response = await monday.api(query, { variables });
      const group = response.data.create_group;
      const groupOption = { value: group.id, label: group.title };

      setGroupOptions([...groupsOptions, groupOption]);
      onGroupChange(groupOption);
    } catch (error) {
      console.error("Create Group error:", error.message);
    }
  };

  const onModalCancel = () => {
    setShowAddGroupModal(false);
  };

  return (
    <Flex gap={10}>
      <Checkbox
        labelClassName="checkbox-label"
        checked={isFieldActive}
        onChange={onFieldToggle}
        disabled={areFieldsDisabled}
      />

      <Text type="text1" color={isFieldActive ? "primary" : "secondary"}>
        Move to Group
      </Text>

      <div className="target-group">
        <Dropdown
          options={groupsOptions}
          size="small"
          value={selectedOption}
          onChange={onGroupChange}
          disabled={!isFieldActive || areFieldsDisabled}
        />
      </div>

      <AddEntityModal
        entityType="group"
        showModal={showAddGroupModal}
        onAdd={onAddNewGroup}
        onCancel={onModalCancel}
      />
    </Flex>
  );
};

export default TargetGroupSelector;
