import moment from "moment";
import { useContext, useState } from "react";
import SchedulesListContext from "../../context/SchedulesListContext";
import { getCalendarGridDates, recurrenceText, shouldCreateItemOnDate } from "../../utils/utils";

const getSchedulesForDate = (date, schedules) => {
  const dateSchedules = [];
  for (let schedule of schedules) {
    if (schedule.isActive && shouldCreateItemOnDate(schedule.recurrence, date)) {
      dateSchedules.push(schedule);
    }
  }
  return dateSchedules;
};

const CalendarView = () => {
  const { schedules } = useContext(SchedulesListContext);

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [selectedDate, setSelectedDate] = useState(moment());

  const onPrev = () => {
    const date = moment().month(month).year(year).subtract(1, "month");
    setMonth(date.month());
    setYear(date.year());
    setSelectedDate(date.startOf("month"));
  };

  const onNext = () => {
    const date = moment().month(month).year(year).add(1, "month");
    setMonth(date.month());
    setYear(date.year());
    setSelectedDate(date.startOf("month"));
  };

  const onClick = (date) => {
    if (date < moment().month(month).year(year).startOf("month")) {
      onPrev();
    } else if (date > moment().month(month).year(year).endOf("month")) {
      onNext();
    }
    setSelectedDate(date);
  };

  return (
    <div className="calendar-view">
      <div className="center">
        <div className="calendar">
          <div className="month-nav">
            <i className="fas fa-angle-left" onClick={onPrev} />
            <h3>
              {moment().month(month).format("MMMM")} {moment().year(year).format("YYYY")}
            </h3>
            <i className="fas fa-angle-right" onClick={onNext} />
          </div>

          <ul className="weekdays">
            <li>Sun</li>
            <li>Mon</li>
            <li>Tue</li>
            <li>Wed</li>
            <li>Thu</li>
            <li>Fri</li>
            <li>Sat</li>
          </ul>

          <div className="date-grid">
            {getCalendarGridDates(month, year).map((date, idx) => {
              const numSchedulesForDate = getSchedulesForDate(date, schedules).length;

              return (
                <div
                  key={idx}
                  className={`date-cell ${date.month() === month && "current-month-date"} ${
                    date.format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD") && "selected"
                  }`}
                  onClick={() => onClick(date)}
                >
                  <p>{date.date()}</p>

                  {numSchedulesForDate > 0 && (
                    <div className="date-num-tasks">
                      {numSchedulesForDate} {numSchedulesForDate === 1 ? "task" : "tasks"}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="date-schedules">
          <div className="day-date">
            <h2>{selectedDate.format("dddd")}</h2>
            <h4>{selectedDate.format("D MMMM YYYY")}</h4>
          </div>

          <ul className="schedules">
            {getSchedulesForDate(selectedDate, schedules).map(
              ({ itemName, boardName, recurrence }) => (
                <li className="schedule">
                  <div>
                    <p className="item-name">{itemName}</p>
                    <p className="board-name">{boardName}</p>
                    <p className="recurrence">{recurrenceText(recurrence)}</p>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CalendarView;
