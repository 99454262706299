import { useContext } from "react";
import mondaySdk from "monday-sdk-js";
import { Button, Flex, Text } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";

const monday = mondaySdk();

const SubscribeNudge = () => {
  const { showSubscribeNudge } = useContext(ScheduleContext);

  const onClick = () => {
    monday.execute("openPlanSelection", { isInPlanSelection: true });
  };

  return (
    showSubscribeNudge && (
      <Flex direction="Row" justify="Center" gap={15} className="subscribe-nudge">
        <Text type="text2">Please subscribe to a plan to continue using the app</Text>

        <Button color="primary" onClick={onClick}>
          See Plans
        </Button>
      </Flex>
    )
  );
};

export default SubscribeNudge;
