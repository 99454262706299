import { useContext, useState } from "react";
import {
  TableBody,
  TableHeaderCell,
  Table,
  TableHeader,
  Modal,
  ModalHeader,
  ModalContent,
} from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import { ScheduleProvider } from "../../context/ScheduleContext";
import SchedulesListContext from "../../context/SchedulesListContext";
import ItemView from "../../views/ItemView";
import ScheduleRow from "./ScheduleRow";

const ScheduleTable = () => {
  const { schedules, isLoading } = useContext(SchedulesListContext);

  const [inputBoardId, setInputBoardId] = useState("");
  const [inputItemId, setInputItemId] = useState("");
  const [scheduleEdited, setScheduleEdited] = useState(false);

  const onModalClose = () => {
    setInputBoardId("");
    setInputItemId("");

    if (scheduleEdited) {
      window.location.reload(false);
    }
  };

  return (
    <>
      <Heading weight="light" type="h2" align="center" style={{ marginBottom: "2rem" }}>
        Your Scheduled Tasks
      </Heading>

      <Modal
        contentSpacing
        id="story-book-modal"
        onClose={onModalClose}
        show={inputItemId !== ""}
        width="35rem"
      >
        <ModalHeader>
          <Heading type="h2">Edit Schedule</Heading>
        </ModalHeader>
        <ModalContent>
          {inputBoardId && (
            <>
              <ScheduleProvider
                isInvokedFromList={true}
                inputBoardId={inputBoardId}
                inputItemId={inputItemId}
                setScheduleEdited={setScheduleEdited}
              >
                <ItemView />
              </ScheduleProvider>
            </>
          )}
        </ModalContent>
      </Modal>

      <Table
        dataState={{ isLoading }}
        columns={[
          {
            id: "boardName",
            width: 250,
          },
          {
            id: "itemName",
            width: 250,
          },
          {
            id: "recurrence",
          },
          {
            id: "nextDate",
            width: 150,
          },
          {
            id: "status",
            width: 120,
          },
          {
            id: "notificationEnabled",
            width: 120,
          },
          {
            id: "action",
            width: 200,
          },
        ]}
      >
        <TableHeader>
          <TableHeaderCell title="Board" />
          <TableHeaderCell title="Task/Item" />
          <TableHeaderCell title="Recurrence" />
          <TableHeaderCell title="Next Date" />
          <TableHeaderCell title="Status" />
          <TableHeaderCell title="Notification" />
          <TableHeaderCell title="Action" />
        </TableHeader>

        <TableBody>
          {schedules.map((schedule, idx) => (
            <ScheduleRow
              id={idx}
              schedule={schedule}
              setInputBoardId={setInputBoardId}
              setInputItemId={setInputItemId}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ScheduleTable;
