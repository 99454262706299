import { useContext } from "react";
import { Flex, Text, Checkbox, Dropdown } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";

const DuplicateItemOption = () => {
  const {
    isSubitem,
    duplicateItem,
    setDuplicateItem,
    withUpdates,
    setWithUpdates,
    areFieldsDisabled,
  } = useContext(ScheduleContext);

  const options = [
    { value: 0, label: "Without updates" },
    { value: 1, label: "With updates" },
  ];

  const onFieldToggle = (e) => {
    setDuplicateItem(e.target.checked);
    if (!e.target.checked) {
      setWithUpdates(false);
    }
  };

  const onOptionChange = (option) => {
    setWithUpdates(!!option.value);
  };

  return (
    <Flex direction="Column" gap={15} align="Start">
      <Flex gap={10}>
        <Checkbox
          labelClassName="checkbox-label"
          checked={duplicateItem}
          onChange={onFieldToggle}
          disabled={areFieldsDisabled}
        />

        <Text type="text1" color={duplicateItem ? "primary" : "secondary"}>
          Duplicate this {isSubitem ? "Subitem" : "Item"}
        </Text>

        {duplicateItem && (
          <div className="with-updates">
            <Dropdown
              options={options}
              size="small"
              value={options[+withUpdates]}
              onChange={onOptionChange}
              disabled={areFieldsDisabled}
              clearable={false}
            />
          </div>
        )}
      </Flex>

      {duplicateItem && (
        <Text type="text1" style={{ paddingLeft: "25px" }}>
          {">"} Following fields will be set on duplicated {isSubitem ? "subitem" : "item"}
        </Text>
      )}
    </Flex>
  );
};

export default DuplicateItemOption;
