import { useEffect, useState } from "react";
import { Toast } from "monday-ui-react-core";

const MessageToast = ({ message, setMessage }) => {
  const [type, setType] = useState("");

  useEffect(() => {
    if (!message) {
      return;
    }

    if (message.includes("success")) {
      setType("positive");
    } else {
      setType("negative");
    }
  }, [message]);

  return (
    <Toast open={message} type={type} onClose={() => setMessage("")} autoHideDuration={5000}>
      {message}
    </Toast>
  );
};

export default MessageToast;
