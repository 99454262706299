import { useContext, useEffect, useState } from "react";
import { Flex, Text, Dropdown, Checkbox } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";

const IndicatorColumnSelector = () => {
  const {
    columns,
    indicatorColumnId,
    setIndicatorColumnId,
    areFieldsDisabled,
    setColumnTypeToAdd,
    setColumnAddCallback,
  } = useContext(ScheduleContext);

  const [isFieldActive, setIsFieldActive] = useState(false);

  const columnOptions = [
    ...columns
      .filter((column) => column.type === "status")
      .map(({ id, title }) => ({ value: id, label: title })),
    { value: "add_column", label: "+ Add column" },
  ];

  useEffect(() => {
    if (indicatorColumnId) {
      setIsFieldActive(true);
    }
  }, [indicatorColumnId]);

  const selectedColumnOption = indicatorColumnId
    ? columnOptions.filter((option) => option.value === indicatorColumnId)[0]
    : null;

  const onFieldToggle = (e) => {
    if (!e.target.checked) {
      setIndicatorColumnId("");
    }
    setIsFieldActive(e.target.checked);
  };

  const onColumnChange = (option) => {
    if (option?.value === "add_column") {
      setColumnTypeToAdd("status");
      setColumnAddCallback(() => (option) => onColumnChange(option));
    } else {
      setIndicatorColumnId(option ? option.value : "");
      if (!option) {
        setIsFieldActive(false);
      }
    }
  };

  return (
    <Flex gap={10}>
      <Checkbox
        labelClassName="checkbox-label"
        checked={isFieldActive}
        onChange={onFieldToggle}
        disabled={areFieldsDisabled}
      />

      <Text type="text1" color={isFieldActive ? "primary" : "secondary"}>
        Add Recurrence Indicator to Column
      </Text>

      <div className="due-date">
        <Dropdown
          options={columnOptions}
          size="small"
          value={selectedColumnOption}
          onChange={onColumnChange}
          disabled={!isFieldActive || areFieldsDisabled}
        />
      </div>
    </Flex>
  );
};

export default IndicatorColumnSelector;
