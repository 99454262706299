import { useContext, useEffect, useMemo, useState } from "react";
import { Flex, Text, Dropdown, Checkbox } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import { STATUS_COLUMN_FIELD, STATUS_VALUE_FIELD } from "../../utils/constants";
import { updateMissingFields } from "../../utils/utils";

const StatusSelector = () => {
  const {
    columns,
    statusColumnId,
    setStatusColumnId,
    statusValue,
    setStatusValue,
    missingFields,
    setMissingFields,
    areFieldsDisabled,
    setColumnTypeToAdd,
    setColumnAddCallback,
  } = useContext(ScheduleContext);

  const [isFieldActive, setIsFieldActive] = useState(false);
  const [valueOptions, setValueOptions] = useState([]);

  const columnOptions = useMemo(
    () => [
      ...columns
        .filter((column) => column.type === "status")
        .map(({ id, title, settings_str }) => ({
          value: id,
          label: title,
          possibleValues: JSON.parse(settings_str).labels,
        })),
      { value: "add_column", label: "+ Add column" },
    ],
    [columns]
  );

  useEffect(() => {
    const fieldName = STATUS_COLUMN_FIELD;

    if (statusColumnId) {
      setIsFieldActive(true);

      if (!columnOptions.map(({ value }) => value).includes(statusColumnId)) {
        if (!missingFields[fieldName]) {
          setMissingFields({ ...missingFields, [fieldName]: true });
        }
      } else {
        if (missingFields[fieldName]) {
          setMissingFields({ ...missingFields, [fieldName]: false });
        }

        const { possibleValues } = columnOptions.filter(
          (option) => option.value === statusColumnId
        )[0];
        setValueOptions(
          Object.entries(possibleValues).map(([key, value]) => ({ value: key, label: value }))
        );

        updateMissingFields(
          STATUS_VALUE_FIELD,
          statusValue,
          Object.keys(possibleValues),
          missingFields,
          setMissingFields
        );
      }
    } else {
      if (missingFields[fieldName]) {
        setMissingFields({ ...missingFields, [fieldName]: false });
      }
      if (missingFields[STATUS_VALUE_FIELD]) {
        setMissingFields({ ...missingFields, [STATUS_VALUE_FIELD]: false });
      }
    }
  }, [statusColumnId, columnOptions, statusValue, missingFields, setMissingFields]);

  const selectedColumnOption = statusColumnId
    ? columnOptions.filter((option) => option.value === statusColumnId)[0]
    : null;

  const selectedValueOption = statusValue
    ? valueOptions.filter((option) => option.value === statusValue)[0]
    : null;

  const onFieldToggle = (e) => {
    if (!e.target.checked) {
      setStatusColumnId("");
      setStatusValue("");
    }
    setIsFieldActive(e.target.checked);
  };

  const onColumnChange = (option) => {
    if (option?.value === "add_column") {
      setColumnTypeToAdd("status");
      setColumnAddCallback(() => (option) => onColumnChange(option));
    } else {
      setStatusColumnId(option ? option.value : "");
      setStatusValue("");

      setValueOptions(
        option
          ? Object.entries(option.possibleValues).map(([key, value]) => ({
              value: key,
              label: value,
            }))
          : []
      );

      if (!option) {
        setIsFieldActive(false);
      }
    }
  };

  const onValueChange = (option) => {
    setStatusValue(option ? option.value : "");
  };

  return (
    <Flex gap={10}>
      <Checkbox
        labelClassName="checkbox-label"
        checked={isFieldActive}
        onChange={onFieldToggle}
        disabled={areFieldsDisabled}
      />

      <Flex gap={30}>
        <Text type="text1" color={isFieldActive ? "primary" : "secondary"}>
          Set Status
        </Text>

        <Flex gap={10}>
          <div className="status">
            <Dropdown
              options={columnOptions}
              size="small"
              value={selectedColumnOption}
              onChange={onColumnChange}
              disabled={!isFieldActive || areFieldsDisabled}
            />
          </div>

          <div className="status-value">
            <Dropdown
              options={valueOptions}
              size="small"
              value={selectedValueOption}
              onChange={onValueChange}
              disabled={!isFieldActive || areFieldsDisabled || !statusColumnId}
            />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default StatusSelector;
