import { useContext } from "react";
import { Flex, Text, Checkbox, Dropdown } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";

const NotificationsOption = () => {
  const {
    columns,
    notificationEnabled,
    setNotificationEnabled,
    peopleColumnId,
    setPeopleColumnId,
    areFieldsDisabled,
    setColumnTypeToAdd,
    setColumnAddCallback,
  } = useContext(ScheduleContext);

  const updatedByUserOption = {
    value: "%updated_by_user%",
    label: "User who last edited schedule",
  };

  const columnOptions = [
    updatedByUserOption,
    ...columns
      .filter((column) => column.type === "people")
      .map(({ id, title }) => ({ value: id, label: `Users in column "${title}"` })),
    { value: "add_column", label: "+ Add column" },
  ];

  const selectedColumnOption = notificationEnabled
    ? peopleColumnId
      ? columnOptions.filter((option) => option.value === peopleColumnId)[0]
      : updatedByUserOption
    : null;

  const onFieldToggle = (e) => {
    if (!e.target.checked) {
      setNotificationEnabled(false);
      setPeopleColumnId("");
    }
    setNotificationEnabled(e.target.checked);
  };

  const onColumnChange = (option) => {
    if (option?.value === "add_column") {
      setColumnTypeToAdd("people");
      setColumnAddCallback(() => (option) => onColumnChange(option));
    } else {
      if (!option) {
        setNotificationEnabled(false);
        setPeopleColumnId("");
      } else {
        if (option.value === updatedByUserOption.value) {
          setPeopleColumnId("");
        } else {
          setPeopleColumnId(option.value);
        }
      }
    }
  };

  return (
    <Flex gap={10}>
      <Checkbox
        labelClassName="checkbox-label"
        checked={notificationEnabled}
        onChange={onFieldToggle}
        disabled={areFieldsDisabled}
      />

      <Text type="text1" color={notificationEnabled ? "primary" : "secondary"}>
        Send Notification to
      </Text>

      <div className="notification">
        <Dropdown
          options={columnOptions}
          size="small"
          value={selectedColumnOption}
          onChange={onColumnChange}
          disabled={!notificationEnabled || areFieldsDisabled}
        />
      </div>
    </Flex>
  );
};

export default NotificationsOption;
