import { useContext, useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import {
  Tab,
  TabList,
  Flex,
  TabPanels,
  ThemeProvider,
  TabPanel,
  Text,
  Link,
} from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import "monday-ui-react-core/tokens";
import CalendarView from "../components/board/CalendarView";
import SchedulesList from "../components/board/ScheduleTable";
import MessageToast from "../components/common/MessageToast";
import SchedulesListContext from "../context/SchedulesListContext";

const monday = mondaySdk();

const BoardView = () => {
  const { schedules, isLoading, message, setMessage } = useContext(SchedulesListContext);

  const [context, setContext] = useState({});
  const [activeTabId, setActiveTabId] = useState(0);

  useEffect(() => {
    monday.listen("context", (res) => {
      setContext((previousContext) => (previousContext === res.data ? previousContext : res.data));
    });
  }, []);

  return (
    <div className={`${context.theme}-app-theme board-view`}>
      <ThemeProvider theme={context.themeConfig} className="board-view">
        {!isLoading && schedules.length === 0 ? (
          <>
            <Heading align="center" style={{ marginTop: "1rem" }}>
              You haven't scheduled any tasks yet
            </Heading>

            <Heading type="h3" align="center">
              Checkout this quick video below to get started
            </Heading>

            <iframe
              width="890"
              height="500"
              src="https://www.youtube.com/embed/vyBo8KSPad4"
              title="Schedule Recurring Tasks on monday.com"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <Text align="center" type="text1">
              Trying this app for the first time? Checkout our{" "}
              <Link
                href="https://superworkday.com/schedule-recurring-tasks/how-to-use.html"
                text="how to use guide"
                inheritFontSize
                inlineText
              />
            </Text>
          </>
        ) : (
          <>
            <Flex justify="Center">
              <TabList>
                <Tab onClick={() => setActiveTabId(0)}>Scheduled Tasks</Tab>
                <Tab onClick={() => setActiveTabId(1)}>Calendar View</Tab>
              </TabList>
            </Flex>

            <TabPanels activeTabId={activeTabId}>
              <TabPanel>
                <SchedulesList />
              </TabPanel>

              <TabPanel>
                <CalendarView />
              </TabPanel>
            </TabPanels>

            <MessageToast message={message} setMessage={setMessage} />
          </>
        )}
      </ThemeProvider>
    </div>
  );
};

export default BoardView;
